import React from "react"
import Layout from "../components/Layout"
import ServicesHeader from "../components/ServicesHeader"
import ServicesBody1 from "../components/ServicesBody1"
import ServiceBody2 from "../components/ServiceBody2"

function service() {
  return (
    <Layout pageTitle="Services">
      
      <ServicesHeader />
      
      <div >
                 <div className="title-service">
                  <div className="section-title">
                  <h2>Overview</h2>
                  </div>
                </div>

                  <div className="side-content-1">
                   
                    <p>
                    We provide tier-1 bespoke personalized construction services from consulting, construction, total management, and maintenance of buildings.
                    </p>
                   
                  </div>
                </div>
      <ServicesBody1 />
     
      <ServiceBody2 />
    </Layout>
  )
}
export default service
